import { StreamInvocationMessage } from '@aspnet/signalr';
import { BaseDto } from './base-dto';

export class Configuracion extends BaseDto {
    public Nombre !: string;
    public Apellidos!:string;
    public Email !: string;
    public Contraseña_email !: string;
    public Telefono1 !: number;
    public Telefono2 !: number;
    public Empresa !: string;
    public Direccion !: string;
    public Codigopostal !: number;
    public Poblacion !: string;
    public Provincia !: string;
    public Pais !: string;
    public Imagen : any=null;
    public ColorPrimario !: string;
    public ColorSecundario !: string;
    public Chat_activo !: number;
    public Agenda_online_activa !: boolean;
    public Notificacion_dialog_suc !: number;
    public Recavisos !: string;
    public Guid_imagen !: string;
    public H_desde!:Date;
    public H_hasta!:Date;
    public send_auto_email_acceso_app!:boolean;
    
    public nombre_facturacion!: string;
    public direccion_facturacion!: string;
    public nif_facturacion!: string;

 } 

import { BaseDto } from '@/shared/dtos/base-dto';
import { licencia } from './licencia';
import { Configuracion } from '../Configuracion';

export class Nutricionista extends BaseDto {
    public email !: string;
    public password !: string;
    public sesion_activa !: boolean;
    public cuenta_activa !: boolean;
    public secretaria !: number;
    public empleado !: number;
    public id_licencia !: number;
    public uid !: string;
    public nombre_pc_sesion !: string;
    public configuracion!: Configuracion;
    public licencia!: licencia;
} 

import { BaseDto } from '@/shared/dtos/base-dto';

export class nutricionista_conf_email extends BaseDto {
    public id_tipo_de_email !: number;
    public id_nutricionista !: number;
    public enviar_cada_dias !: number;
    public email_habilitado !: boolean;
    public numero_citas_a_relizar !: number;
    public maximo_emails_enviar !: number;
    public asunto_email !: string;
    public mensaje_email !: string;
    public nombre_email!: string;
    public fecha!: Date;

} 

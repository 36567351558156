import { store } from '@/store/store';
import { Configuracion } from '@/shared/dtos/Configuracion';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { UtilsString } from '@/utils/utils-string';
import { UtilsNotify } from '@/utils/utils-notify';

@Module({
    namespaced: true,
    name: 'ConfiguracionModule',
    store,
    dynamic: true
})
class ConfiguracionModule extends VuexModule {
    public Configuracion: Configuracion = new Configuracion();
    public ConfiguracionForBack: Configuracion = new Configuracion();
    public urlImage: string = '';
    public name_ntt:string="";
    @Action({ commit: 'onGetConfiguracion' })
    public async getConfiguracion(id: any) {
        return await ssmHttpService.get(API.Configuracion + '/' + id);
    }

    @Action({ commit: 'onGetConfiguracionName' })
    public async getConfiguracionName(id: any) {
        return await ssmHttpService.get(API.Configuracion + '/name/' + id);
    }

    @Action({ commit: 'onGetConfiguracionForBack' })
    public async getConfiguracionForBack(id: any) {
        return await ssmHttpService.get(API.Configuracion + '/' + id, null, false);
    }

    @Action({ commit: 'onUpdateConfiguracion' })
    public async modificarConfiguracion(Configuracion: Configuracion) {
        await ssmHttpService.put(API.Configuracion + '/' + Configuracion.id, Configuracion);
    }

    @Action
    public async GetImageNutricionista(Configuracion: Configuracion) {
        if (!UtilsString.IsNullOrWhiteSpace(UtilsString.ValueOf(Configuracion.id)) && !UtilsString.IsNullOrWhiteSpace(UtilsString.ValueOf(Configuracion.Guid_imagen))) {
            await ssmHttpService.get(API.NutricionistaImagen + '/?id=' + Configuracion.id + '&guid=' + Configuracion.Guid_imagen, null, false)
                .then(e => this.onGetImageNutricionista(e))
                .catch(() => this.onGetImageNutricionista(undefined));
        } else {
            this.onGetImageNutricionista(undefined);
        }

    }
    @Mutation
    public onGetImageNutricionista(res: any) {
        if (res !== undefined) {
            this.urlImage = res
                ? 'data:image/png;charset=utf-8;base64,' + res
                : 'https://image.flaticon.com/icons/svg/1868/1868212.svg' // some default image
        } else {
            this.urlImage = 'https://image.flaticon.com/icons/svg/1868/1868212.svg';// some default image
        }
    }

    @Mutation
    public onGetConfiguracion(res: Configuracion) {
        this.Configuracion = new Configuracion(res);
    }
    @Mutation
    public onGetConfiguracionName(res: string) {
        this.name_ntt = res;
    }
    @Mutation
    public onGetConfiguracionForBack(res: Configuracion) {
        this.ConfiguracionForBack = new Configuracion(res);
    }

    public NameToast: string = 'Configuración';
    @Mutation
    public onUpdateConfiguracion(res: any) {
        UtilsNotify.NotificacionSuccess("Configuracion", this.NameToast + ' actualizada correctamente');
    }
}
export default getModule(ConfiguracionModule);
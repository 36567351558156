import { store } from '@/store/store';
import { Nutricionista } from '@/shared/dtos/admin/Nutricionista';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { change_pwd } from '@/shared/dtos/change-pwd';

@Module({
    namespaced: true,
    name: 'NutricionistaModule',
    store,
    dynamic: true
})
class NutricionistaModule extends VuexModule {
    public Nutricionistas: Nutricionista[] = [];
    public Nutricionista: Nutricionista = new Nutricionista();

    @Action({ commit: 'onGetNutricionistas' })
    public async getNutricionistas() {
        return await ssmHttpService.get(API.Nutricionista);
    }

    @Action({ commit: 'onGetNutricionista' })
    public async getNutricionista(id: any) {
        return await ssmHttpService.get(API.Nutricionista + '/' + id);
    }

    @Action
    public async guardarNutricionista(Nutricionista: Nutricionista) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.Nutricionista, Nutricionista.toJson());
    }

    @Action({})
    public async postChangePwd(dto: change_pwd) {
        return await ssmHttpService.post(API.Nutricionista + '/change_pwd', dto.toJson(), true, false, true);
    }


    @Action
    public async modificarNutricionista(Nutricionista: Nutricionista) {
        await ssmHttpService.put(API.Nutricionista, Nutricionista);
    }

    @Action
    public async eliminarNutricionista(Nutricionista: Nutricionista) {
        await ssmHttpService.delete(API.Nutricionista + '/' + Nutricionista.id, null, false);
    }

    @Mutation
    public onGetNutricionistas(res: Nutricionista[]) {

        this.Nutricionistas = res ? res.map((x) => new Nutricionista(x)) : []
    }

    @Mutation
    public onGetNutricionista(res: Nutricionista) {
        this.Nutricionista = new Nutricionista(res);
    }

}
export default getModule(NutricionistaModule);
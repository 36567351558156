import { store } from '@/store/store';
import { nutricionista_conf_email } from '@/shared/dtos/nutricionista_conf_email';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'nutricionista_conf_emailModule',
    store,
    dynamic: true
})
class nutricionista_conf_emailModule extends VuexModule {
    public nutricionista_conf_emails: nutricionista_conf_email[] = [];
    public nutricionista_conf_email: nutricionista_conf_email = new nutricionista_conf_email();

    @Action({ commit: 'onGetnutricionista_conf_emails' })
    public async getnutricionista_conf_emails() {
        return await ssmHttpService.get(API.nutricionista_conf_email);
    }

    @Action({ commit: 'onGetnutricionista_conf_email' })
    public async getnutricionista_conf_email(id: any) {
        return await ssmHttpService.get(API.nutricionista_conf_email + '/' + id);
    }
    @Action({ commit: 'onGetnutricionista_conf_emails' })
    public async getnutricionista_conf_emails_activados() {
        return await ssmHttpService.get(API.nutricionista_conf_email + '/mails_activados');
    }
    @Action
    public async guardarnutricionista_conf_email(nutricionista_conf_email: nutricionista_conf_email) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.nutricionista_conf_email, nutricionista_conf_email.toJson());
        this.getnutricionista_conf_emails();
    }
    @Action
    public async modificarnutricionista_conf_emails(nutricionista_conf_emails: nutricionista_conf_email[]) { 
        await ssmHttpService.put(API.nutricionista_conf_email+ '/mails_activados_update/' , JSON.stringify(nutricionista_conf_emails),false);
    }
    @Action
    public async modificarnutricionista_conf_email(nutricionista_conf_email: nutricionista_conf_email) {
        await ssmHttpService.put(API.nutricionista_conf_email, nutricionista_conf_email);
        this.getnutricionista_conf_emails();
    }

    @Action
    public async eliminarnutricionista_conf_email(nutricionista_conf_email: nutricionista_conf_email) {
        await ssmHttpService.delete(API.nutricionista_conf_email + '/' + nutricionista_conf_email.id, null, false);
        this.getnutricionista_conf_emails();
    }

    @Mutation
    public onGetnutricionista_conf_emails(res: nutricionista_conf_email[]) {

        this.nutricionista_conf_emails = res ? res.map((x) => new nutricionista_conf_email(x)) : []
    }

    @Mutation
    public onGetnutricionista_conf_email(res: nutricionista_conf_email) {
        this.nutricionista_conf_email = new nutricionista_conf_email(res);
    }

}
export default getModule(nutricionista_conf_emailModule);
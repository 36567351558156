































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Configuracion } from "@/shared/dtos/Configuracion";
import { change_pwd } from "@/shared/dtos/change-pwd";
import ConfiguracionModule from "@/store/modules/Configuracion-module";
import nutricionista_conf_emailModule from "@/store/modules/nutricionista_conf_email-module";
import { RouterNames } from "@/router/routernames";
import { JwtService } from "@/shared/services/JwtService";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { API } from "@/shared/api";
import { UtilsString } from "@/utils/utils-string";
import { ssmHttpService } from "@/shared/services/http-service";
import { nutricionista_conf_email } from "@/shared/dtos/nutricionista_conf_email";
import NutricionistaModule from "@/store/modules/Admin/Nutricionista-module";
@Component({
  components: {
    DxFileUploader,
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    ConfiguracionFichaOpciones: () =>
      import("./configuracion-ficha-opciones.vue"),
    ConfiguracionMailMarketing: () =>
      import("./configuracion-mail-marketing.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class ConfiguracionFormulario extends Vue {
  public configuracion: Configuracion = new Configuracion();
  public numeroversion: string = "";
  public configmailmarketing: boolean = false;
  public show_change_pwd: boolean = false;
  public conf_email!: nutricionista_conf_email;
  public new_pwd: change_pwd = new change_pwd();
  public async created() {
    if (JwtService.jwtDecode().nameid !== undefined) {
      this.configuracion.id = JwtService.jwtDecode().nameid;
      await ConfiguracionModule.getConfiguracion(this.configuracion.id);
      this.configuracion = ConfiguracionModule.Configuracion;
      nutricionista_conf_emailModule.getnutricionista_conf_emails_activados();
    }
    this.getversion();
  }
  data() {
    return {
      files: [],
    };
  }
  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        ConfiguracionModule.modificarConfiguracion(this.configuracion).then(
          () => {
            nutricionista_conf_emailModule
              .modificarnutricionista_conf_emails(this.emails_marketing)
              .then(() => this.toHome());
          }
        );
      }
    });
  }
  public submit_change_pwd() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        NutricionistaModule.postChangePwd(this.new_pwd).then(async () => {
          this.show_change_pwd = false;
          var instancia = await (
            await import("@/shared/services/message-service")
          ).ssmMessageService;
          instancia.toastsuccesful("Actualizado correctamete");
        });
      }
    });
  }
  public get NombreClinica() {
    return UtilsString.ValueOf(this.configuracion.Empresa);
  }
  public get NombreNutri() {
    return UtilsString.ValueOf(this.configuracion.Nombre);
  }
  public get getUrl() {
    return API.NutricionistaImagen + "/?id=" + this.configuracion.id;
  }
  public get getImagen() {
    return ConfiguracionModule.urlImage;
  }
  @Watch("show_change_pwd")
  public Clar_data_change_pwd() {
    if (this.show_change_pwd) {
      this.new_pwd = new change_pwd();
    }
  }

  public change_pwd() {}
  public async ImagenSubida() {
    await ConfiguracionModule.getConfiguracionForBack(this.configuracion.id);
    ConfiguracionModule.GetImageNutricionista(
      ConfiguracionModule.ConfiguracionForBack
    );
  }
  public toHome() {
    this.$router.push({ name: RouterNames.Home });
  }
  public getversion() {
    return ssmHttpService.get(API.VersionBack).then(this.onVersion.bind(this));
  }
  public onVersion(res: any) {
    this.numeroversion = res + " - " + (process.env.VUE_APP_VERSION as string);
    return res.Result;
  }
  public saveConfMailsAndTest() {
    this.saveConfMails().then(async () => {
      var instancia = await import("@/store/modules/Emails/Emails-module");
      instancia.default.SendEmailTestMarketing(
        this.conf_email.id_tipo_de_email
      );
    });
  }
  public saveConfMails() {
    this.configmailmarketing = false;
    return nutricionista_conf_emailModule.modificarnutricionista_conf_emails(
      this.emails_marketing
    );
  }
  public config_email(configuracion_email: nutricionista_conf_email) {
    this.conf_email = configuracion_email;
    this.configmailmarketing = true;
  }
  public get emails_marketing() {
    return nutricionista_conf_emailModule.nutricionista_conf_emails;
  }
}
